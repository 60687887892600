
.sectionContainer{
  background-color: #f95284;
  color: white;
  width: 100%;
  
}
.title {
  /* @apply --marketplaceH1FontStyles; */
  margin-top: 0;
  align-content: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: center;
  margin: 20px;
  padding: 40px;
}

.infoBox{
  align-items: center;
    border-bottom: 2px solid #000;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}


.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  display: list-item;
  list-style-type: "⚪";
  padding-inline-start: 1ch;
  animation: color-change 3s ease-in-out infinite;
  font-size: 25px;


}

.steps {
  padding-left: 35px;
}


.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
.imageContainer {
  width:50%;
  @media(max-width:738px){
    display:none;
  }
}
.image {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
  url('../../../assets/images/image3.jpg');
  width:100%;
  background-repeat: no-repeat;

}


.suBtn{
    background-color: #f95284;
    color: white;
    text-transform: uppercase;
    border: none;
    border-radius: 0%;
    padding: 15px 15px 15px 15px;
    width: 200px;
    margin-right: 40px;
    border-radius: 90px;
    font-weight: bold;
    margin-top:20px;
    text-transform: uppercase;
    width: 200px;
    font-size:14px;

    transition: var(--transitionStyleButton);

    &:hover {
      transform: scale(1.04);
      box-shadow: var(--boxShadowSectionLocationHover);
    }
  }