
.title {
  /* @apply --marketplaceH1FontStyles; */

  margin-top: 20px;
  margin-bottom: 0;
  text-align: center;

}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  /* @apply --marketplaceH2FontStyles; */
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

#categories{
  min-height: 300px;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background-color: #fff;
}

.categoryButtonContainer{
  width: 33%;
  display: flex;
  justify-content: center;
  @media(max-width:738px){
    padding: 20px 0;
    width: 100%;

  }
}


.categoryButton{
  position:relative;
  width:90%;

}
.categoryButton img{
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}
.categoryButton img{
  filter:brightness(0.5);
}

.categoryText{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 22px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 1px 2px black;
}

.categoryText.show {
  display:block;
}

.catImg{
  margin-top:20px;
  object-fit: cover;
  filter:blur(.6px);
  border: 2px black solid;
  border-radius: 30px;
  height:auto;
  max-width:97%;
}

