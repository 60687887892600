@import '../../../styles/customMediaQueries.css';

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}


.container {
  display: flex;
  background-color: black;
  color: white;
}
.title {
  composes: h1 from global;
  margin-top: 0;
  text-align: left;
}

.steps {
  display: flex;
  flex-direction: column;
  position: relative;

}

.container img {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

@media(max-width: 1350px){
  .stepimage{
    display: none;
  }
  .stepdiv {width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;}
  .container {justify-content: center;}
  .step {position:static!important}
}

.step {
  @media (--viewportMedium) {
    width: 100%;
    position: absolute;
  }
  display: flex;
  flex-direction: row;
  align-items: center;

  
}

.stepNum {
  background: var(--marketplaceColor);
  padding: 3px 10px;
  border-radius: 100px;
  font-size: 20px;
  margin: 0 20px;

  @media (--viewportMedium){
    padding: 3px 15px;

  }
}
.stepdiv {width:100%; margin-left: 60px; padding-top: 10%;}

.stepimage {width:40%}

.step:first-of-type {
  @media (--viewportLarge) {
    top: 0px;
    left:0px;
  }
}

.step:nth-child(even) {
  @media (--viewportLarge) {
    top: 100px;
    left:10%;

  }
}

.step:last-of-type {
  @media (--viewportLarge) {
    top:200px;
    left:20%;

  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;


  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
    font-size: 2em;
    line-height: 3em;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
