@import '../../styles/customMediaQueries.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 67.5vh;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }
}

.heroRoot {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}





/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}


/* Why join bottom section */

#joinToday{
  text-align:center;
  background-color: black;
  color:white;
  padding: 40px 0px;
}

.suBtn{
    background-color: #f95284;
    color: white;
    text-transform: uppercase;
    border: none;
    border-radius: 0%;
    padding: 15px 15px 15px 15px;
    width: 200px;
    margin-right: 40px;
    border-radius: 90px;
    font-weight: bold;
    margin-top:20px;
    text-transform: uppercase; 
    width: 200px;
    font-size:14px;
    transition: var(--transitionStyleButton);

    &:hover {
      transform: scale(1.04);
      box-shadow: var(--boxShadowSectionLocationHover);
    }
  }
  /* Features - Arielle Consider Renaming */
  #featureHeader{
    width: 100%;
    text-align:center;
    padding-bottom: 20px;
  }

  #features{
    width:100%;
    display:inline-flex;
    background-color: white; 
    min-height: 300px;
    border-bottom: 2px black solid;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 40px 0 125px;
  }

  .featureButton{
    position:relative;
    border-radius: 100px;
  }
  
  .featureText{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-size: 22px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 1px 2px black;
  }
  
  .featureTest{
    display: none;
  }



  .featureTest.show {
    display:block;
    position:absolute;
    transition: width 2s, height 2s, transform 2s;
  
  }

  .featureTest>h1{
    text-transform: uppercase;
    color: rgb(249, 82, 132);
    font-weight: 800;
    line-height: 1;
  
  }
  
  .featureContainer{
    width:220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .featureImg{
    height:200px;
    width:200px;
    border-radius: 100px;
  }

  .featureContainer{flex-direction: column; padding: 20px 0;}
  .categoryButtonContainer{width:100%; padding: 20px 0px;}

  .featureContainer .featureTest {
    display:none;
    position: absolute;
  }
  .featureContainer:hover .featureTest {
    display:block;
  }

  .featureContainer .featureButton a .featureText {
    display:none;
  }

  .featureContainer:hover .featureButton a .featureText {
    display:block;

  }

  .featureContainer:hover .featureButton a img {
    filter:brightness(0.5);

  }
  
  .featureTest > h1 {
    font-size: 25px;

  }




  /**
 * ----------------------------------------
 * animation feature 1
 * ----------------------------------------
 */

 .featureTest0{
  left: -322px;  -webkit-animation: featureTest0 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: featureTest0 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation-name: featureTest0;
          animation-duration: 1s;
          animation-delay: 0.1s;
          animation-fill-mode: forwards;
        
          animation-timing-function: ease-in;
}

@-webkit-keyframes featureTest0 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
}
@keyframes featureTest0 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
}

  /**
 * ----------------------------------------
 * animation feature 2
 * ----------------------------------------
 */

 .featureTest1{
  bottom: -89px;
  -webkit-animation: slide-right 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-right 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation-name: featureTest0;
          animation-duration: 1s;
          animation-delay: 0.1s;
          animation-fill-mode: forwards;
        
          animation-timing-function: ease-in;
}


 @-webkit-keyframes featureTest2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}
@keyframes featureTest2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}

  /**
 * ----------------------------------------
 * animation feature 3
 * ----------------------------------------
 */

 .featureTest2{
  right:-360px;
  -webkit-animation: slide-right 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-right 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation-name: featureTest2;
          animation-duration: 1s;
          animation-delay: 0.1s;
          animation-fill-mode: forwards;
        
          animation-timing-function: ease-in;
}


 @-webkit-keyframes featureTest2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}
@keyframes featureTest2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}



@media only screen and (max-width: 768px) {
  .featureTest{display:none!important}

  #features{flex-direction: column;}
  .featureContainer{flex-direction: column; padding: 20px 0;}

  #joinToday{text-align:center;}

  .featureText{
    display:block!important;
  }
}