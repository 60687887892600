@import '../../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (--viewportLarge) {
    border-radius: 40px;
    border: solid 36px var(--matterColorBright);
  }
}

.heroContent {
  margin: 0 24px 0 24px;
  text-align: center;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  @media (--viewportMedium) {
    font-size: 3.5em;
    line-height: 50px;
    width: 800px;
    margin: auto;
  }

  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);
  composes: animation;


}
.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {

  composes: h4 from global;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  composes: animation;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
    font-size: 21px;
    line-height: 48px;
  }
}
.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroButtons {
  display: flex;
  flex-direction:column;
  align-items: center;

  justify-content: space-evenly;
  @media (--viewportSmall) {
    flex-direction:row;
  }
}
.heroButton{
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.04);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

.heroButton:first-child {
  color: white;
  background-color:var(--marketplaceColor);
}
.heroButton:last-child {
  background-color: white;
  color:var(--marketplaceColor);
}




.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;
  margin: 10px;
  width: 200px;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}
.heroButtonFEDelay {
  animation-delay: 0.3s;
}
